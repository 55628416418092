.whole_selector {
  padding: 2px 10px;
  font-size: 13px;
  font-weight: normal;
  background: #eee;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  transition: 0.2s linear;
  &:hover {
    background: #ddd;
    border: 1px solid #ccc;
    transition: 0.2s linear;
  }
  &:active {
    background: #ccc;
    border: 1px solid #bbb;
    transition: 0.2s linear;
  }
}
.show {
  display: flex;
}
.hide {
  display: none;
}
.loader_container {
  div {
    padding-bottom: 10%;
    div {
      padding: 0;
      margin: 0;
    }
  }
}
.field-to-export {
  div.radio {
    padding: 5px 15px 5px 30px;
    .child-field-to-export {
      padding: 5px;
    }
  }
}

.save-dash-toolbar-footer .toolbar-export-csv-button {
  border-radius: 4px !important;
  padding-left: 15px;
  padding-right: 10px;
}

.export-format {
  width: 115px;
  display: flex;
  flex-direction: column;

  .export-format-button {
    background: transparent;
    color: #4183c4;
    padding: 5px 0 5px 0;
    text-align: left;
    font-size: 14px;
    font-weight: 400;

    &:hover {
      background: transparent;
      color: #4183c4;
    }
  }

  .export-format-button:first-child {
    padding-bottom: 8px !important;
    border-bottom: 1px solid #f0f0f0;
  }

  .export-format-button:last-child {
    margin-top: 3px;
  }
}

.ExportCSV {
  .toolbar-export-csv-button-custom.button {
    max-height: 26px !important;
    min-height: 26px !important;
  }
  i.external.alternate.icon {
    margin-right: 0 !important;
  }
}

#app
  #content
  #pages
  .toolbar-export-csv-button-custom.button
  > *:not(.label) {
  line-height: 21px !important;
}
