@import '../../assets/styles/colors.scss';

#top-selector {
  max-width: 400px;
  .dropdown {
    max-width: 100%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    .item {
      color: #999db3;
      font-weight: 500;
      font-size: 0.8em;
      padding: 3px 15px !important;
      background-color: $color-primary !important;
      border-bottom: 1px solid #51577a;
    }
    .text {
      max-width: 100%;
      min-width: 270px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &.visible {
      .text {
        min-width: 400px;
        max-width: 100%;
      }
    }
  }
}
