.recent-sms {
  flex: 6;
  padding: 2rem 2rem 0 2rem;

  .recent-sms-header {
    display: flex;
    justify-content: space-between;
  }

  .recent-sms-body {
    overflow: scroll;
    height: calc(100vh - 175px) !important;
  }
}

.sms-item-details-popup {
  color: #7c7a7a;
}
