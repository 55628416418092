@import '../../../../assets/styles/colors.scss';

.save-dash-toolbar-footer {
  display: flex;
  flex-direction: row;
  .toolbar-pagination {
    font-size: 10px !important;
    margin-right: 6px !important;
    margin-bottom: 7px !important;
    &.__package {
      min-width: 130px;
      font-size: 12px !important;
    }
  }
  .toolbar-search-input input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .toolbar-filter-button {
    border-radius: 0 !important;
    padding: 0 10px 0 15px !important;
  }
  .toolbar-export-csv-button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .search-by {
    background: $color-light;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0.55rem 1rem;
    height: 32px;
  }
}
