.statusTag {
  position: relative;
  top: 6px;
  border-radius: 3px;
  padding: 2px 8px;
  color: #fff;
  background: none;
  &.approved,
  &.accepted {
    background: #21ba45;
  }

  &.pending {
    background: #e9d631;
  }

  &.cancelled {
    background: #b1b1b1;
  }

  &.rejected,
  &.declined {
    background: #db2829;
  }

  &.cleared {
    background: #326ec8;
  }

  &.amended {
    background: #ffa500;
  }
}
