@import '../../../assets/styles/colors.scss';

.wallet-card {
  display: flex;
  width: 49%;
  box-shadow: 0px 0px 4.09503px rgba(0, 0, 0, 0.25);
  margin-bottom: 1rem;
  padding: 1rem;
  &__logo {
    margin-right: 1.5rem;
    align-self: center;
    img {
      width: 53px;
      height: 53px;
    }
  }
  &__body {
    margin-right: auto;
    h3,
    p {
      margin: 0;
    }
    &__balance {
      font-weight: 600;
      font-size: 26px;
      color: #7c7c7c;
      margin-bottom: 0.5rem !important;
      font-family: 'Poppins';
    }
    &__date {
      color: #acacac;
    }
  }
  &__more {
    text-decoration: underline;
    color: #2185d0;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &__modal {
    padding: 0 1rem;
    padding-bottom: 5px;
    &__balance {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 30px;
      padding: 5px 0;
      color: map-get($i-color-list, yaleBlue);
      border-bottom: 1px solid map-get($i-color-list, yaleBlue);
      &__meta {
        font-size: 15px;
      }
      & > * {
        margin: 0;
      }
    }
    &__details {
      padding: 1rem 0;
      font-size: 15px;
      color: map-get($i-color-list, yaleBlue);
      &__item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
      }
      &__due {
        span {
          color: #e37d8f;
        }
      }
      &__label {
        font-weight: 700;
      }
      &__value {
        color: map-get($i-color-list, yaleBlue);
      }
    }
  }
}
