.line-chart-wrapper {
  margin: auto !important;
  min-height: 230px !important;
  padding: 15px !important;
  width: 100% !important;

  &__icon--view {
    color: #fff;
    text-decoration: underline;
  }
  
  &__icon--view:hover {
    cursor: pointer;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
  }

  .title {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
  }
  .date {
    color: #fff;
    font-size: 12px;
  }
  .count {
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    display: inline-block;
    overflow-wrap: break-word;
    max-width: 100%;
    line-height: 1em;

    .currency {
      color: #fff;
      font-size: 16px;
      text-transform: uppercase;
      margin: 0 0 -5px 5px;
      display: inline-block;
      vertical-align: bottom;
    }
  }
  .line-chart-container {
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(72, 78, 115, 0.05);
    min-height: 100px;
    margin-top: 30px;
    .recharts-wrapper,
    .recharts-surface {
      width: 100% !important;
    }
  }
}
