.single_project_overview_header {
  display: flex;
  align-items: center;
}
.SingleProject {
  padding: 16px;

  .project-card {
    min-height: 545px;
    width: 100%;
    padding: 15px;

    .right-text {
      text-align: right;
    }

    .project-icon {
      display: inline-block;
      max-width: 30%;

      .thumbnail {
        width: 50px;
        height: 50px;
        font-size: 18px;
        font-weight: bold;
      }
    }

    .project-name {
      max-width: 70%;
      display: inline-block;
      font-weight: bold;
      font-size: 20px;
      vertical-align: middle;
    }

    .left-text,
    .right-text {
      display: inline-block;
      width: 50%;
      margin-bottom: 15px;
      font-size: 15px;

      .title {
        margin-bottom: 5px;
        font-weight: bold;
      }
    }

    .pilot-status-badge {
      margin-top: auto;
      margin-left: auto;
      margin-bottom: 3.5rem;
      background: #57d9a3;
      color: #fff;
      padding: 3px 11px;
      border-radius: 4px;
    }

    .footer {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 15px;
      text-align: right;
      font-size: 15px;
    }
  }

  .members-card,
  .drop-outs-card {
    height: 212px;
    width: 100%;
    padding: 15px;

    .count {
      font-size: 30px;
      font-weight: bold;
      display: inline-block;
      margin-bottom: 5px;
    }

    .members-card-header,
    .drop-outs-card-header {
      padding-bottom: 8px;

      .members-card-header-left,
      .drop-outs-card-header-left {
        width: 70%;
        display: inline-block;

        .members-card-header-title,
        .drop-outs-card-header-title {
          font-weight: bold;
          font-size: 13px;
          margin-bottom: 5px;
        }

        .members-card-header-date,
        .drop-outs-card-header-date {
          color: grey;
          font-size: 12px;
        }
      }

      .members-card-header-right,
      .drop-outs-card-header-right {
        display: inline-block;
        text-align: right;
        width: 30%;
        vertical-align: top;
        padding-top: 5px;

        .person-icon {
          font-size: x-large;
        }
      }
    }

    .line-chart-container {
      border-radius: 15px;
      background: rgba(72, 78, 115, 0.05);
      min-height: 100px;
      margin-top: 30px;
      text-align: center;

      .recharts-wrapper,
      .recharts-surface {
        width: 100% !important;
      }
    }
  }
}
