.SingleUser {
  &__body {
    display: flex;
    position: relative;
    &__details {
      .header {
        height: unset !important;
        margin: 0 !important;
        padding: 0 !important;
        border: unset !important;
        font-size: 15px !important;
      }

      .list {
        .item {
          color: #737171;

          .header {
            color: #737171 !important;
            font-size: 13px !important;
          }
        }
      }
    }
    &__right {
      &__header {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        h2 {
          flex: 1;
          margin: 0;
        }
        &__invite_code {
          display: flex;
          align-items: center;
          background: #eee;
          padding: 0.1rem 1rem;
          border-radius: 5px;
          img {
            height: 19px;
            margin-left: 5px;
          }
          p {
            margin: 0;
            padding: 5px 5px 5px 15px;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }

  form {
    .phone_number {
      margin-left: -38px !important;
    }
    min-width: 30vw;
    margin-bottom: 30px;
    margin-right: 3rem;

    hr {
      border: solid 0.5px #eee;
    }

    .disabled.field,
    .field.disabled > label,
    .disabled.input,
    .disabled.dropdown {
      opacity: 0.8 !important;
      color: #2f2e2ede !important;
    }

    .disabled.field.phone-number-field {
      opacity: 1 !important;
      .ui.action.disabled.input {
        opacity: 1 !important;
        input {
          color: #b5b5b5;
          opacity: 1 !important;
        }
      }
    }

    .submit-btn-wrapper {
      text-align: right;

      button[type='submit'] {
        color: #fff;
        background-color: #2185d0;
      }
    }
  }
}

.actionCard {
  margin-top: 100px !important;
}

.pannels-column {
  .save-dash-toolbar {
    .right-component {
      button {
        margin-left: 6px !important;
      }
    }
  }
}

.is-flex {
  display: flex;
}

.phone-form {
  .field {
    margin-top: 20px !important;
  }
}
.influencer-field {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  padding: 0 !important;

  .right {
    .ui.disabled.checkbox label {
      cursor: not-allowed !important;
    }
  }
}
