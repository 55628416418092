.detailsPopup {
  .status-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    color: #fff;
    font-size: 1.1em;
    background-color: #4d99ff;
    outline: none !important;

    span {
      margin-top: 2px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
.request-modal {
  &.header {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
}
.request-form {
  resize: none !important;
  margin: 10px 0 !important;
  &__button {
    margin: 10px 0 !important;
    float: right;
  }
}
