@import '../../../../assets//styles/colors.scss';

.settingModals {
  .item {
    font-size: 15px;
    color: $color-primary-dark !important;
  }
  &__content {
    padding: 1.9em 1.3em;
    form {
      .field {
        label {
          margin-bottom: 0.4rem !important;
        }
      }
      .ui.labeled.input > .label {
        border-right-color: transparent;
        font-weight: 500;
      }
      .action {
        text-align: right;
      }
    }
  }

  .ui.pointing.menu {
    padding: 0 0.7em;
    .item {
      padding: 0.85714286em 0.54285714em;
    }
  }
}
