.create-new-ad {
  flex: 4;
  width: 45%;
  padding: 1.5rem;
  margin-bottom: 10rem;

  .send-btn-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;

    .publish-checkbox {
      margin-top: 1rem;
    }
  }
  .header {
    display: flex;
    width: 380px;
  }
  .editing_image {
    background: rgb(34, 33, 33) !important;
    position: relative;
    width: 380px;
    height: 255px;
    overflow: hidden;
    margin-bottom: 2rem;
    margin-top: 12px;

    &__file {
      object-fit: cover;
      min-width: 100%;
    }
    input {
      display: none;
    }
    &__button {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
      );
      opacity: 0.6;
      position: absolute;
      top: 0%;
      left: 0%;
      color: white;
      font-weight: 700;
      text-align: center;
      border: 0px none;
      height: inherit;
      width: inherit;
      cursor: pointer;
      background-color: transparent;
      cursor: pointer;
      :hover {
        background-color: transparent;
        border: 0px none;
      }
    }
  }
  .uploaded_field {
    cursor: auto;
    margin-top: 12px;

    &__file {
      background: rgb(34, 33, 33) !important;
      position: relative;
      width: 380px;
      height: 255px;
      overflow: hidden;
      &--image {
        object-fit: cover;
        min-width: 100%;
        padding-bottom: 1rem;
      }
      &--attachment {
        display: none;
      }
      &--button {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0.5)
        );
        opacity: 0.6;
        position: absolute;
        top: 0%;
        left: 0%;
        color: white;
        font-weight: 700;
        text-align: center;
        border: 0px none;
        height: inherit;
        width: inherit;
        cursor: pointer;
        background-color: transparent;
        cursor: pointer;
        :hover {
          background-color: transparent;
          border: 0px none;
        }
      }
    }
  }
  .ui.search.dropdown > .text {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
    white-space: nowrap;
  }

  .action-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    &__checkbox {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  form {
    overflow: inherit;
    overflow-y: scroll;
    height: calc(100vh - 220px);
    padding: 0 1rem 0.5rem 0;
    &::-webkit-scrollbar {
      display: none;
    }
    .input_title {
      padding-top: 1rem;
    }
  }
  .ui.button.header__clear {
    align-items: flex-end;
  }
}
