.recent-ads {
  flex: 6;
  padding: 2rem 2rem;
  font-size: 16px;

  .recent-ads-header {
    display: flex;
    justify-content: space-between;
    width: 500px;
  }

  .recent-ads-body {
    overflow: scroll;
    height: calc(100vh - 200px) !important;
    width: 500px;
  }

  .view-image-modal {
    position: relative;
    background-color: red;
    display: none;

    .arrow-left {
      position: absolute;
      top: 50%;
    }
    .arrow-right {
      position: absolute;
      top: 50%;
    }
  }
}

.ad_field {
  display: flex;
  padding-right: 1rem;
  &__image {
    height: 60px;
    width: 110px;
    position: relative;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    );
    overflow: hidden;
    &__picture {
      height: 60px;
      width: 110px;
      object-fit: cover;
      opacity: 0.5;
    }
    &__button {
      position: absolute;
      bottom: 2%;
      right: 65%;
      cursor: pointer;
      border: 0px none;
      outline: none;
      background-color: transparent;
      &__icon {
        color: white;
      }
    }
  }
}

.advertisement {
  display: flex;
  flex-direction: column;
  flex: 1;
  &:last-child {
    justify-content: flex-end;
  }
}
.advertisement_heading {
  display: flex;
  flex: 1;
  padding: 5px;
  ::first-letter {
    text-transform: capitalize;
  }
  span {
    color: #323652;
    font-weight: 400;
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    padding-left: 16px;
    flex: 1;
  }
}
.advertisement_actions {
  display: flex;
  align-self: flex-end;
  justify-self: baseline;
  padding-top: 8px;
}

.view-image-modal-content {
  position: relative !important;

  &__arrow-left {
    position: absolute;
    top: calc(100% / 2 - 7px);
    background: #fff;
    border-radius: 50%;
    margin: 1rem !important;
    width: 2rem !important;
    height: 2rem !important;
    z-index: 10;
    left: 0;
  }
  &__arrow-right {
    position: absolute;
    top: calc(100% / 2 - 7px);
    background: #fff;
    border-radius: 50%;
    margin: 1rem !important;
    width: 2rem !important;
    height: 2rem !important;
    z-index: 10;
    right: 0;
  }
}
.ui.basic.button.delete {
  box-shadow: none !important;
  display: flex !important;
  flex-direction: row !important;
  padding: 0 4px;
}
.ui.basic.button.delete:hover {
  box-shadow: none !important;
  font-weight: bold;
}
.ui.basic.button.delete:focus {
  box-shadow: none !important;
  font-weight: bold;
}
.ui.basic.button > img {
  box-shadow: none !important;
  width: 18px !important;
  height: 18px !important;
  padding-right: 4px;
  border: none !important;
}
.ui.image img,
.ui.image svg {
  margin: auto;
}

.ui.image,
.ui.image {
  margin: auto;
}
