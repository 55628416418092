.nested-accordion-toggle {
  &__content {
    padding-left: 1.5rem !important;
  }
  .ui.accordion .title:not(.ui) {
    padding: 0;
    margin: 0;
  }
  .ui.disabled.checkbox label {
    cursor: not-allowed !important;
  }
}
