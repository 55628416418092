.selectedItems{
  &__item{
    margin: .5rem 0;
    position: relative;
    .label{
      margin-bottom: .2rem;
    }
    &__clear{
      position: absolute;
      top: -20px;
      right: 0px;
      cursor: pointer;
    }
  }
}