.send-bulk-sms {
  flex: 4;
  padding-top: 2rem;
  .field {
    margin-top: 20px;
  }
  .fill {
    flex-direction: column !important;
  }
  .send-btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .ui.search.dropdown > .text {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
    white-space: nowrap;
  }
  .sms-description {
    margin-bottom: 0 !important;
  }
  .description-count {
    display: block;
    text-align: right;
    margin-bottom: 1rem;
    color: #8c8d8f;
    position: relative;
    top: -24px;
    right: 9px;
  }

  .block-input {
    display: block !important;
    &.width.equal.fields .field {
      max-width: 100% !important;
      display: block;
      margin-bottom: 0.8rem;
    }
  }

  form {
    overflow: inherit;
    overflow-y: scroll;
    height: calc(100vh - 220px);
    padding: 0 1rem 0.5rem 0;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
