.approvalDetails {
  * {
    font-family: Roboto;
    color: #7e7e7e;
  }
  &__headerTop {
    display: flex;
    justify-content: space-between;
  }
  &__header {
    font-weight: 600;
  }
  &__button {
    &__status {
      position: relative;
      float: right;
      border-radius: 3px;
      padding: 2px 8px;
      color: #fff;
      background: #b1b1b1;
      margin-left: auto;
      cursor: pointer;
      &:hover {
        background: #919191;
      }
    }
  }

  &__status {
    color: #db2829;
    align-self: center;
  }

  &__approved,
  &__success {
    color: #21ba45;
    padding-left: 6px;
  }

  &__pending,
  &__submitted {
    color: #e9d631;
    padding-left: 6px;
  }
  &__approvals {
    margin-top: 8px;
    &__item {
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      &__dot {
        font-size: 29px;
        position: relative;
        top: 6px;
        display: inline-block;
        margin-right: 4px;
      }
      &__phone {
        font-size: 12px;
        margin-left: 8px;
        i.icon {
          font-size: 8px;
        }
      }
    }
  }
}
