.empty-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  opacity: 0.65;
  margin-top: 15%;
  .segment {
    border: none !important;
    box-shadow: none !important;
    min-width: 300px !important;
    .header,
    .sub.header,
    .ui.center.aligned.header,
    *,
    & {
      text-align: center !important;
      align-items: center !important;
      justify-content: center !important;
    }
  }
}
