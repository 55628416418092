$color-primary: #484e73;
$color-primary-dark: #323652;
$color-light: #ffffff;
$color-grey: #fbfbfb;
$color-dark-grey: #393d5880;
$color-dark: #353535;
$color-dark-semantic-btn-bg: #e0e1e2;
$color-danger: #fc5739;
$border-color: rgba($color-primary, 0.1);
$border-color-dark: rgba($color-primary-dark, 0.5);
$menu-bg: rgba($color-light, 0.1);
$color-new-green: #26b663;
$color-new-red: #db2829;
$color-new-yellow: #ffc600;
$color-light-dark: #393d58;
$color-label-green: #57d9a3;
$color-label-blue: #028cfe;
$color-incon-blue: #2185d0;
$color-black-de: #000000de;

$i-color-list: (
  green: #57d9a3,
  blue: #4c9aff,
  yaleBlue: #53566e,
  blues: #0d71bb,
  royalBlue: #323652,
  sapphireBlue: #4657717e,
  orange: #ff991f,
  red: #ff5630,
  purple: #8766b6,
  darkerBlue: #3d5fd8,
  pink: #c0295d,
  red-mod: #fb4e7b,
  silverGray: #c4c4c4,
);

$colors: #34688f, #f6cb06, #f0a007, #f48605, #c35d05, #a8414a, #282725,
  #6a8a82, #a27c27, #573838, #1142bf, #0f83f2, #00aff1, #a69674,
  #6564a5, #6a74a7, #f38a31, #f35738;

:export {
  blueIconColor: $color-incon-blue;
  approvedColor: #48ff5b;
  declinedColor: #ff4869;
  pendingColor: #ffab48;
  successColor: #48ff5b;
  clearedColor: #326ec8;
  amendedColor: #ffa500;
}
