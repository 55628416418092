@import '../../assets/styles/colors.scss';

.sidebar {
  width: 200px;
  padding-top: 0px;
  .header {
    padding-bottom: 0px !important;
    height: 64px !important;
    align-items: center !important;
    .title {
      font-size: 1em !important;
      font-weight: 600;
      color: $color-primary-dark;
    }
  }
}
