.phone-input {
  .input {
    .label {
      border: 1px solid #e8e8e8;
      border-right: none !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      line-height: 30px !important;
    }
    &.error {
      .label {
        color: #9f3a38;
        border-color: #e0b4b4 !important;
      }
    }
  }
}
