@import '../../../../../assets/styles/colors.scss';

.microloans-interest-details {
  &__item {
    display: flex;
    width: 100%;

    .input-separator {
      width: 10px;
      height: 2px;
      margin: 7px;
      background-color: map-get($i-color-list, silverGray);
    }

    &__left {
      width: 50% !important;
    }
    &__right {
      width: 50% !important;
    }

    &__range-label {
      margin-bottom: 5px;
      font-size: 13px;
    }
    &__range-input {
      padding-right: 28px;
      align-items: center;
    }

    &__icons {
      display: flex;
      align-items: center;
      margin-top: -16px;
    }
  }
}
