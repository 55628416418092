.sms-item {
  display: flex;
  justify-content: space-between;

  .sms-subject {
    display: block;
    font-size: 15px;
  }

  .sms-description {
    font-size: 13px;
  }

  .sms-item-details {
    margin: 10px 1rem 0px;
    display: flex;
    align-items: center;

    .sms-sent {
      min-width: 150px;
      max-width: 150px;
      margin-right: 2rem;
      margin-bottom: 10px;

      &__icon {
        height: 25px;
        position: relative;
        top: 5px;
        margin-right: 0.5rem;
      }

      &__label {
        font-size: 1em;
      }

      .green {
        color: #15ae70;
      }

      .red {
        color: #e4544b;
      }
    }
  }

  .status-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    color: #fff;
    font-size: 1.1em;
    margin-left: 20px;
    background-color: #4d99ff;
    margin-right: 20px !important;
    outline: none !important;

    span {
      margin-top: 2px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .highlighter {
    color: #4183c4;
  }
}
.sms-item-details-popup{
  padding: 0 0 0 4px;
  h5 {
    margin-top: 12px;
    margin-bottom: 4px;
  }
  .header {
    margin-top: 0;
  }
}
