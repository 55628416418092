.create-organization {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  &__title {
    font-size: 1.2em;
    font-weight: 400;
    padding: 20px 0 30px;
  }
  &__left {
    min-width: 400px;
    margin-right: 3rem;
  }
  &__right {
    min-width: 300px;
  }
  &__image {
    width: 122px;
    height: 122px;
    margin-top: 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-size: cover;
    border-radius: 5px;
    &--select {
      cursor: pointer;
      top: 0.7rem;
    }
  }
  margin-bottom: 20px;
}
