@import '../../../../assets/styles/colors.scss';

.line-chart {
  &__container {
    width: 280px;
    color: $color-light;
    margin: 10px;
    padding: 20px;
    position: relative;
    border-radius: 4px;
  }

  &__title {
    font-size: 1.3em;
    display: flex !important;
    justify-content: space-between !important;
    align-items: flex-start !important;
    height: 32px !important;

    &__thetruetitle {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__graph {
    margin: 20px 0px 0px;
    padding: 20px;
    border-radius: 4px;
  }

  &__heading {
    font-size: 0.9em;
    text-transform: uppercase;
  }

  &__date {
    font-weight: 400;
  }

  &__amount {
    h3 {
      font-weight: bold;
      font-size: 1.8em;
      overflow-wrap: break-word;
    }
  }
  &__icon {
    font-size: 0.75em;
    cursor: pointer;
    min-width: 85px;
    max-width: 85px;
    display: flex;
    justify-content: flex-end;
    &--view {
      text-decoration: underline;
      text-transform: capitalize;
      font-weight: 500;
    }
    &--arrow {
      width: 15%;
      padding-left: 0.3em;
    }
  }
}

.header {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.group-details {
  display: flex;
}

.values {
  display: flex;
  flex-direction: column;
  width: 100px;
  color: rgb(122, 121, 121);
}

.right-align {
  display: flex;
  justify-content: flex-end;
}

.space {
  margin-bottom: 10%;
}
