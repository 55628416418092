@import '../../../../../assets/styles/colors.scss';

.microloans-repayment-settings {
  &__item {
    display: flex;

    .input-separator {
      width: 10px;
      height: 2px;
      margin: 7px;
      background-color: map-get($i-color-list, silverGray);
    }

    &__range-label {
      margin-bottom: 5px;
    }
    &__range-input {
      padding-top: 5px;
      padding-right: 28px;
      align-items: center;
    }

    &__minimum-installments {
      display: flex;
      align-items: center;
      width: 47%;

      &__input {
        width: 90%;
        margin-right: 15px !important;
      }
    }
  }
}
