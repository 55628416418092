@import '../../../assets/styles/colors.scss';

section.group-list {
  .group-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 5px;
    padding: 5px 0;
    min-height: 60px;

    &:hover {
      cursor: pointer;
      background-color: #f8f8f8;
    }

    .thumbnail {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 20px;
      object-fit: cover;
    }

    .details {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 5px;
      border-bottom: solid 2px rgba(72, 78, 115, 0.1);

      &:hover {
        cursor: pointer;
        background-color: #f8f8f8;
      }

      .info {
        .name {
          font-size: 1.2em;
          padding-bottom: 5px;
        }

        .contact {
          display: flex;
          align-items: center;
          color: #888;
          font-size: 0.9em;
          font-weight: 400;
          margin-top: 2px;

          .contact-item {
            display: flex;
            min-width: 150px;
            max-width: 150px;
            overflow: hidden;
            justify-content: flex-start;
            align-items: center;
            text-overflow: ellipsis;
            margin-right: 20px;
            white-space: nowrap;

            b {
              margin-right: 5px;
            }

            .status-success {
              color: #21ba45;
            }
            .status-pending {
              color: #a79600;
            }
            .status-failed {
              color: #db2829;
            }

            .group-item-icon {
              margin-right: 10px;
            }
          }
          .time {
            max-width: 250px !important;
            min-width: 200px !important;
          }
          .more-info {
            color: $color-incon-blue;
            min-width: 0;
            b {
              text-decoration-line: underline;
            }
          }
        }
      }

      .right-info {
        width: 90px;
      }

      .status {
        width: 70px !important;
        text-align: center;
        text-transform: capitalize;
        font-size: 0.9em;
        display: inline-block;
        padding: 5px 10px;
        color: #fff;
        border-radius: 3px;
      }

      .mtn {
        background-color: #f6cb06 !important;
      }

      .airtel {
        background-color: #fc5739 !important;
      }

      .access {
        background-color: #314196 !important;
      }
      .info-icon {
        margin-bottom: 0.65rem;
      }
    }
  }
}

.status-popup {
  span {
    color: red;
    .icon {
      border: 2px solid red;
      border-radius: 50%;
      padding-bottom: 10px;
      width: 20px;
      height: 20px;
      font-size: x-small;
      margin-right: 5px;
    }
  }
  .status-message {
    padding-top: 10px;
  }
}

.info-popup {
  span {
    color: $color-new-green;
    .icon {
      border: 2px solid $color-new-green;
    }
  }
}

.info-message {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  padding-top: 10px;
  info-details {
    text-align: right !important;
  }
}

.refresh-status-buttton {
  text-align: center;
}

.transaction-info {
  float: right;
  display: flex;
  img {
    margin-top: -1px;
  }

  &__credit {
    color: $color-new-green !important;
  }

  &__debit {
    color: $color-new-red !important;
  }
}
