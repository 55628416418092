.genders {
  .row {
    padding-bottom: unset !important;
  }
}

.Total__members {
  width: 100% !important;

  &__top {
    display: flex;
    justify-content: space-between;

    &__icon {
      .icon {
        width: unset;
      }
    }
  }

  &__title {
    font-size: 1.1em;
    margin-bottom: 0;
  }

  &__description {
    font-size: 1.8em;
    margin-top: 10px;
  }
}
