@import 'assets/styles/_variables';

.recent-activities {
  flex: 2;
}
.activities {
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(#{$bodyMinusToolbarHeigh} + 20px);
}
