.accordion-toggle {
  &__main {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 16px;
    padding: 0 !important;
    .left {
      display: flex;
    }
    .right {
      .ui.disabled.checkbox label {
        cursor: not-allowed !important;
      }
    }
  }
  &__content {
    padding: 0 0 0 1.5rem;
    margin-bottom: 0.7rem;
    display: flex;
    justify-content: space-between;
    .left {
      color: rgba(115, 113, 113, 0.95);
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
