.popup-content,
.popup-content::before {
  background-color: #5b6081 !important;
  border: none !important;
  box-shadow: none !important;
}

.popup-content {
  div.content {
    color: #fff !important;
  }
}

.large-icon {
  width: 32px !important;
  height: auto !important;
}
