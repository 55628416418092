.bar-chart-wrapper {
  margin: auto !important;
  min-height: 260px !important;
  padding: 15px !important;
  width: 100% !important;

  .title {
    color: #000;
    font-weight: bold;
    font-size: 14px;
  }

  .date {
    color: grey;
    font-size: 12px;
  }

  .bar-chart-container {
    padding-top: 20px !important;
    margin-top: -15px;
    border-radius: 15px;
    background: rgba(72, 78, 115, 0.05);
    min-height: 200px;
    text-align: center !important;

    .recharts-wrapper,
    .recharts-surface {
      width: 100% !important;
    }
  }
}
