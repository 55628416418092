@import '../../../assets/styles/colors.scss';

.filter_transactions {
  margin-left: 0.5rem !important;

  .divider {
    margin: 0 !important;
  }

  .reset {
    .text {
      color: $color-danger;
    }
  }
  &__tag {
    display: flex !important;
    align-items: center !important;
  }
}
