.projects-table {
  padding: 16px;
  .project-name {
    display: flex;
    align-items: center;

    .thumbnail {
      margin-right: 15px;
    }
  }
  table {
    padding: 1rem 0;
    thead {
      th:first-child {
        width: 26%;
      }
    }
    tbody {
      display: block;
      height: calc(100vh);
      padding-bottom: 280px;
      overflow: auto;
      td:first-child {
        width: 26%;
      }
    }
    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }
}
