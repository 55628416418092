.settings-tab-items {
  padding-top: 8px;

  .ui.pointing.secondary.menu > a {
    padding-bottom: 24px;
  }
  .ui.pointing.secondary.menu {
    margin-bottom: 16px;
  }

  .ui.segment.active.tab {
    padding: 0px 8px !important;
    border: none !important;
    border-radius: 0px;
  }
}

.feature-settings {
  width: 500px;
}
